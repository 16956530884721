import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Container from '../components/container';
import Hero from '../components/hero';
import Layout from '../components/layout';
import NavDropdown from '../components/navDropdown';
import Observable from '../components/observable';
import Recipe from '../components/recipe';
import Section from '../components/section';
import { fluidImageType, nodesOf, recipeType } from '../helper/prop-types';
import { H2, P } from '../components/typography';

class RecipePage extends Component {
    static propTypes = {
        data: PropTypes.shape({
            file: fluidImageType,
            allDatoCmsRecipe: nodesOf(recipeType),
        }).isRequired,
    };

    state = {
        activeRecipe: null,
    };

    /**
     * Setzt das gerade aktive Rezept für die Dropdown-Navigation
     * @param {string} slug
     */
    updateActiveRecipe(slug) {
        window.history.pushState({}, 'Rezepte', `/rezepte#${slug}`);
        this.setState({ activeRecipe: slug });
    }

    /**
     * Render
     */
    render() {
        const { data } = this.props;
        const { activeRecipe } = this.state;

        return (
            <Layout
                conclusionBoxes={['products', 'wheretobuy']}
                title="Einfache Rezepte für bayerischen Wurstgenuss"
                description="Einfach genießen: Köstliche Vesper-Kreationen, deftige Leberkäs-Gerichte oder herzhafter Wurstsalat – hier gibt es Inspiration für bayerischen Wurstgenuss."
            >
                <Hero title="Einfach Genießen!" image={data.file.childImageSharp.fluid} />
                <Section>
                    <Container maxWidth="small">
                        <H2 textCentered>Bayerische Genussmomente für jeden Anlass</H2>
                        <P textCentered>
                            Vom herzhaften Wurstsalat über zünftige Leberkäs-Gerichte bis zu
                            köstlichen Vesper-Kreationen – hier findest du ein paar gute Antworten
                            auf die Frage: Was esse ich heute?
                            <br />
                            Lass dich inspirieren!
                            <br />
                            <br />
                            <strong>Guten Appetit!</strong>
                        </P>
                    </Container>
                </Section>
                <NavDropdown
                    links={data.allDatoCmsRecipe.edges.map(({ node: { slug, title } }) => ({
                        to: `/rezepte/#${slug}`,
                        title,
                        active: activeRecipe === slug,
                    }))}
                />

                {data.allDatoCmsRecipe.edges.map(({ node: recipe }) => (
                    <Section key={recipe.id} id={recipe.slug} padding="60px 10px">
                        <Container maxWidth="small">
                            <Observable
                                once={false}
                                callback={() => this.updateActiveRecipe(recipe.slug)}
                            >
                                <Recipe
                                    slug={recipe.slug}
                                    title={recipe.title}
                                    image={recipe.image}
                                    ingredients={recipe.ingredients}
                                    ingredientsRaw={recipe.ingredientsRaw}
                                    preparation={recipe.preparation}
                                    products={recipe.products}
                                    portions={recipe.portions}
                                />
                            </Observable>
                        </Container>
                    </Section>
                ))}
            </Layout>
        );
    }
}

export default RecipePage;

export const query = graphql`
    query {
        file(relativePath: { eq: "keyvisual/keyvisual-recipes.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        allDatoCmsRecipe {
            edges {
                node {
                    id
                    slug
                    title
                    image {
                        fluid(maxWidth: 770) {
                            ...GatsbyDatoCmsFluid
                        }
                        alt
                        title
                    }
                    category {
                        id
                        title
                    }
                    time
                    difficulty
                    portions
                    products {
                        id
                        slug
                        title
                        image {
                            fluid(maxWidth: 200) {
                                ...GatsbyDatoCmsFluid
                            }
                        }
                    }
                    ingredients: ingredientsNode {
                        childMarkdownRemark {
                            html
                        }
                    }
                    ingredientsRaw: ingredients
                    preparation {
                        # ... on DatoCmsPhoto {
                        #     model {
                        #         type: apiKey
                        #     }
                        #     image {
                        #         fluid(maxWidth: 600) {
                        #             ...GatsbyDatoCmsFluid
                        #         }
                        #     }
                        # }
                        ... on DatoCmsStep {
                            model {
                                type: apiKey
                            }
                            title
                            description: descriptionNode {
                                childMarkdownRemark {
                                    html
                                }
                            }
                            descriptionRaw: description
                        }
                        ... on DatoCmsVideo {
                            model {
                                type: apiKey
                            }
                            video {
                                height
                                provider
                                providerUid
                                thumbnailUrl
                                title
                                url
                                width
                            }
                        }
                    }
                }
            }
        }
    }
`;
