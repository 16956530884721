import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import Container from './container';
import { StyledLink } from './link';
import { colors } from '../helper/variables';
import { fluidImageType } from '../helper/prop-types';
import { H2, H3, H4 } from './typography';
import { mq, gapable } from '../helper/stylehelper';

const Steps = styled.ol`
    list-style: none;
    counter-reset: recipe-step-counter;
    margin: 0;
    padding: 0;
`;
const Image = styled(Img)`
    ${gapable('s')};
`;
const Step = styled.li`
    counter-increment: recipe-step-counter;
    display: block;
    overflow: hidden;
    position: relative;
    padding: 0 0 1em 0;

    &:last-child {
        padding-bottom: 0;
    }

    p {
        margin-top: 0;
        &:last-child {
            margin-bottom: 0;
        }
    }

    img {
        max-width: 100%;
    }
`;

const Ingredients = styled.div`
    margin-bottom: 1em;

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin-bottom: 0.5em;
        line-height: 1.35em;
    }
`;

const Grid = styled.div`
    ${mq.medium`
        display: flex;
        flex-wrap: no-wrap;
        margin-top: ${rem(20)};

        > * {
            flex: 1 0 ${rem(270)};

            &:first-child {
                border-right: 1px solid ${colors.grayLight};
                margin-right: ${rem(30)};
                max-width: ${rem(270)};
                padding-right: ${rem(30)};
            }
        }
    `}
}

`;

const preparationSteps = new Map()
    .set('photo', ({ image }, key) => (
        <Step key={`photo--${key}`}>
            <Img fluid={image.fluid} />
        </Step>
    ))
    .set('video', ({ video }, key) => (
        <Step key={`video--${key}`}>
            <a href={video.url} target="_blank" rel="noopener noreferrer">
                <img src={video.thumbnailUrl} alt="Thumbnail" />
            </a>
        </Step>
    ))
    .set('step', ({ title = null, description }, key) => (
        <Step key={`step--${key}`}>
            {title ? <H2 as="h4">{title}</H2> : null}
            <div dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
        </Step>
    ));

const Recipe = ({ title, image, ingredients, ingredientsRaw, preparation, products, portions }) => (
    <Container>
        <H2 gap={{ small: 'm', medium: 'l' }} textCentered>
            {title}
        </H2>
        <Image
            gap={{ small: 'l', medium: 'none' }}
            fluid={image.fluid}
            alt={image.alt}
            title={image.title}
        />
        <Grid>
            <div>
                <H3>Zutaten ({portions} Portionen)</H3>
                <Ingredients
                    dangerouslySetInnerHTML={{ __html: ingredients.childMarkdownRemark.html }}
                />

                <H4>Verwendete Produkte</H4>
                {products.map(product => (
                    <StyledLink
                        gap={{ small: 'xl', medium: 's' }}
                        key={`link--${product.slug}`}
                        to={`/produkte/#${product.slug}`}
                    >
                        {product.title}
                    </StyledLink>
                ))}
            </div>
            <div>
                <H3>Zubereitung</H3>
                <Steps>
                    {preparation.map((el, i) =>
                        preparationSteps.get(el.model.type).call(null, el, i)
                    )}
                </Steps>
            </div>
        </Grid>
        <script type="application/ld+json">
            {`
                {
                    "@context": "http://schema.org/",
	                "@type": "Recipe",
                    "name": "${title}",
                    "recipeIngredient": "${ingredientsRaw}",
                    "recipeInstructions": "${preparation
                        .map(el => {
                            if (el.model.type === 'step') {
                                return `${el.title ? `**${el.title}**\n` : ''}${el.descriptionRaw}`;
                            }
                            return '';
                        })
                        .join('\n\n')}",
                    "image":"${image.fluid.src}"
                }
            `}
        </script>
    </Container>
);

Recipe.propTypes = {
    title: PropTypes.string.isRequired,
    image: fluidImageType.isRequired,
    ingredients: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
            html: PropTypes.string,
        }),
    }).isRequired,
    ingredientsRaw: PropTypes.string.isRequired,
    preparation: PropTypes.arrayOf(PropTypes.object).isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    portions: PropTypes.number.isRequired,
};

export default Recipe;
